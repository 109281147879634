import * as React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import { TbArrowBigRight } from "react-icons/tb"

export default function Thanks() {
  return (
    <Layout pageTitle="Web Design & Development">
      <section className="reading">
        <article>
          <h1>Thank You</h1>
          <p>
            Thanks for getting in touch! I'll get back to you as soon as
            possible.
          </p>
          <Link to="/" className="button">
            Home
            <TbArrowBigRight />
          </Link>
        </article>
      </section>
    </Layout>
  )
}
